import DataTransferUtil from '@/utils/data-transfer.util';

export const LOG_COLUMNS = [
  {
    title: '操作类型',
    key: 'type',
    slot: 'type',
    minWidth: 100,
  },
  {
    title: '操作内容',
    key: 'content',
    minWidth: 180,
  },
  {
    title: '操作人',
    key: 'opt_nick_name',
    minWidth: 140,
  },
  {
    title: '操作时间',
    key: 'create_time',
    minWidth: 180,
  },
];

export const OPERATION_TYPE = {
  OTHER: 0,
  CREATE: 1,
  EDIT: 2,
  DELETE: 3,
  ALL: 'all',
};

export const BASIC_OPERATION_TYPE_TEXT = {
  [OPERATION_TYPE.OTHER]: '其他',
  [OPERATION_TYPE.CREATE]: '新增',
  [OPERATION_TYPE.EDIT]: '修改',
  [OPERATION_TYPE.DELETE]: '删除',
};

export const BASIC_OPERATION_OPTION_TEXT = {
  [OPERATION_TYPE.ALL]: '全部',
  [OPERATION_TYPE.CREATE]: '新增',
  [OPERATION_TYPE.EDIT]: '修改',
  [OPERATION_TYPE.DELETE]: '删除',
};

export const OPERATION_TYPE_OPTIONS = DataTransferUtil
  .enumTransfer2Options(BASIC_OPERATION_OPTION_TEXT);
